a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: #1b6ec2 !important;
    text-decoration: none !important;
}

html {
    font-size: 14px;
}
/*

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}*/

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

a {
    text-decoration: none;
}

    a:hover {
        text-decoration: underline;
    }


.menu-border-top {
    border-top: 1px solid rgba(255, 255, 255, .25);
}

.nav-menu {
    width: 260px;
}

    .nav-menu + .main,
    .nav-menu + .main + .modal-backdrop {
        left: 260px;
    }

.main {
    left: 0;
    right: 0;
}

.small-screen .nav-menu + .main,
.small-screen .nav-menu + .main-backdrop {
    width: 100vw;
}
