/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.fw-bold600 {
    font-weight: 600;
}

.rbt a.dropdown-item {
    text-decoration: none;
}

.btn.btn-fab {
    z-index: 999;
    line-height: 1.33;
    text-align: center;
    width: 30px;
    height: 30px;
    border-radius: 50% !important;
    position: fixed;
    bottom: 1em;
    right: 1em;
    margin: 0;
    padding: 0;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 20%) !important;
    opacity: 0.9;
}

    .btn.btn-fab:hover {
        opacity: 1;
    }

.btn.btn-md.btn-fab {
    width: 40px;
    height: 40px;
}

.btn.btn-lg.btn-fab {
    width: 50px;
    height: 50px;
}

.btn.btn-xl.btn-fab {
    width: 70px;
    height: 70px;
}


.navbar-nav .nav-item .nav-link.active {
    font-weight: bold;
}


.input-group > .btn:not(:hover) {
    background-color: white;
    border-color: #ced4da;
}


.cursor-pointer,
.info {
    cursor: pointer;
}

.tooltip .tooltip-inner {
    text-align: left;
    padding: 0.75em 1em 1em 1em;
    min-width: 100px;
    max-width: 500px;
}

.avatar {
    width: 30px;
}

a.no-decoration,
a.no-decoration:active,
a.no-decoration:focus,
a.no-decoration:hover {
    text-decoration: none;
}


ul.dropdown-menu li .dropdown-item.active *,
ul.dropdown-menu li .dropdown-item:active * {
    color: white !important;
}


.invalid-feedback {
    display: block !important;
    /*  padding: 0.15rem 0.5rem;
    margin-top: 0.1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220,53,69,.9);
    border-radius: 0.25rem;
    width: max-content !important;
    margin: 0.5em 0;*/
}

/*    .invalid-feedback:before {
        content: "\f33a";
        font-family: bootstrap-icons !important;
        color: yellow;
        margin-right: 0.35rem;
        line-height: 1;
        vertical-align: -0.125em;
    }*/

.nav-tabs {
    border-bottom-width: 0;
}

    .nav-tabs.admin-tabs {
        margin-bottom: 0px;
    }

.nav.nav-tabs.admin-tabs,
.nav.nav-tabs.admin-tabs .nav-item .nav-link:hover,
.nav.nav-tabs.admin-tabs .nav-item .nav-link:focus,
.nav.nav-tabs.admin-tabs .nav-item .nav-link.active {
    border-bottom-color: transparent;
}

    .nav.nav-tabs.admin-tabs .nav-item {
        margin-right: -1px;
    }

.autocomplete .input-group > .form-control:disabled + .btn {
    opacity: 1;
}


.table > tbody > tr:last-child > td,
.table > tbody > tr:last-child > th {
    border-bottom-width: 0;
}

.fieldset {
    padding: 1em;
    border: 1px solid #dddddd;
    border-radius: 3px;
}

    .fieldset legend {
        float: unset;
        font-size: 1rem;
        font-weight: bold;
        /*width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
        font-size: calc(1.275rem + 0.3vw);
        line-height: inherit;*/
    }

.table > tbody > tr > td {
    min-width: 80px;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
}

/** Date picker **/

.rdp-input-group .rdp-form-control {
    /* no clear button : we need radius on input on the right too ! */
    border-radius: 4px !important;
}

.rdp-input-group .rdp-popover .popover-header * {
    user-select: auto;
}

.rdp-popover .popover-body {
    padding: 0.6em;
}

    .rdp-popover .rdp-calendar > tbody > tr > td[data-day]:hover {
        background-color: #f0f0f0;
    }

.rdp-popover .rdp-calendar > tbody > tr > td[data-day].bg-primary {
    color: white;
}

.rdp-popover .rdp-calendar .u-today-button {
    background-color: white;
    border-color: #ced4da;
    color: #212529;
}

    .rdp-popover .rdp-calendar .u-today-button:hover,
    .rdp-calendar .u-today-button:focus,
    .rdp-calendar .u-today-button:active {
        background-color: #0d6efd;
        border-color: #0d6efd;
        color: #ffffff;
    }

.no-focus-outline {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}



/** Phone (display like a input-group-sm) */

.PhoneInput {
    padding: 0;


    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0;
    font-size: 0.875rem;
    border-radius: 0.2rem;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}


    .PhoneInput.PhoneInput--focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
        z-index: 3;
    }



    .PhoneInput .PhoneInputInput {
        margin: 0;
        padding: 0;
        outline: none;
        border: 1px solid #ced4da;
        min-height: calc(1.5em + 0.5rem + 2px);
        font-size: 0.875rem;
        border-radius: 0 !important;
        padding: 0.25rem 0.5rem !important;
    }



    .PhoneInput .PhoneInputCountry {
        margin: 0;
        padding: 0.375rem 0.6rem 0.375rem 0.75rem;
        border: 1px solid #ced4da;
        border-radius: 0.2rem 0 0 0.2rem !important;
        border-right: 0;
    }

        .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
            margin-left: 0.75rem;
        }

.popover,
.popover .popover-inner,
.popover .popover-inner .header,
.popover .popover-inner .popover-body {
    --bs-popover-max-width: 500px;
}

    .popover .popover-inner .popover-body {
        max-height: 300px;
        overflow-y: auto;
    }

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #0366d6;
    display: inline;
    margin: 0;
    padding: 0;
}

.link-button:hover {
    text-decoration: underline;
}


.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
}


.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-y-scroll {
    overflow-y: scroll;
}


.page-header-button {

}

    .page-header-button:hover {
        background-color: rgba(0,0,0, 0.033) !important;
    }